import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BaseButton } from './Button'

const StyledButtonGroup = styled.div`
  display: flex;
`

const Button = styled(BaseButton)`
  background-color: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.greyShade2}
  border-radius: ${props => props.theme.borderRadius};
  color: ${props => props.theme.color.greyShade4};
  
  &:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }

  &:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &&[disabled] {
    border-color: ${props => props.theme.color.greyShade1};
    color: ${props => props.theme.color.greyShade3};
    cursor: not-allowed;
    user-select: none;
    z-index: 0;
  }

  ${({ selected }) =>
    selected &&
    `
    &&& {
      background-color: ${props => props.theme.color.brandPrimaryTint5};
      border-color: ${props => props.theme.color.greyShade3}
      color: ${props => props.theme.color.brandPrimary};
      font-weight: bold;
      z-index: 1;
    }
  `}

  &:hover,
  &:active {
    border-color: ${props => props.theme.color.greyShade4};
    z-index: 2;
  }

  &:focus {
    box-shadow: ${props => props.theme.color.boxShadow};
    outline: none;
    z-index: 2;
  }

  &:active {
    background-color: ${props => props.theme.color.brandPrimaryTint8}
  }
`

export const ButtonGroup = ({ actions, ...rest }) => {
  return (
    <StyledButtonGroup {...rest}>
      {actions.map(action => {
        const { label, selected, onClick, ...buttonRest } = action
        return (
          <Button
            {...buttonRest}
            key={label}
            selected={selected}
            onClick={onClick}
          >
            {label}
          </Button>
        )
      })}
    </StyledButtonGroup>
  )
}
ButtonGroup.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
      selected: PropTypes.bool
    })
  )
}
export default ButtonGroup
