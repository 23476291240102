import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { space } from 'styled-system'

const Button = styled.button`
  background-color: transparent;
  color: ${props => props.theme.color.brandPrimary};
  cursor: pointer;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes.base};
  ${space}

  &:hover {
    color: ${props => props.theme.color.brandPrimaryShade2};
  }
  &:focus {
    text-decoration: underline ${props => props.theme.color.boxShadow};
    outline: none;
  }
  &:active {
    color: ${props => props.theme.colorPrimary};
    text-decoration: underline;
  }
  &[disabled],
  &.disabled {
    color: ${props => props.theme.color.greyShade3};
    cursor: not-allowed.;
    user-select: none;
  }
  &.destructive {
    color: ${props => props.theme.color.dangerLight};

    &:active {
      color: ${props => props.theme.color.dangerDark};
    }
    &:hover {
      color: ${props => props.theme.color.dangerDark};
    }
  }
`

export const Action = ({ children, ...rest }) => (
  <Button {...rest}>{children}</Button>
)

Action.propTypes = {
  children: PropTypes.node
}
export default Action
