import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Action } from '../js/components/Action';
import { Box } from '../js/components/Box';
import { Button } from '../js/components/Button';
import { ButtonGroup } from '../js/components/ButtonGroup';
import { Spinner } from '../js/components/Spinner';
import { Props, Playground } from 'docz';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <Playground __position={0} __code={'<ThemeProvider theme={themeConWeb}>\n  <div className=\"row\">\n    <Button m=\"2\">Default</Button>\n    <Button m=\"2\" className=\"secondary\">\n      Secondary\n    </Button>\n    <Button m=\"2\" className=\"destructive\">\n      Destructive\n    </Button>\n    <Action m=\"2\">Default</Action>\n    <Action m=\"2\" className=\"destructive\">\n      Destructive\n    </Action>\n  </div>\n  <div className=\"row\">\n    <Button m=\"2\">\n      Default <Spinner size=\"xs\" />\n    </Button>\n    <Button m=\"2\" className=\"secondary\">\n      Secondary <Spinner size=\"xs\" />\n    </Button>\n    <Button m=\"2\" className=\"destructive\">\n      Destructive <Spinner size=\"xs\" />\n    </Button>\n    <Action m=\"2\">\n      Default <Spinner size=\"xs\" />\n    </Action>\n    <Action m=\"2\" className=\"destructive\">\n      Destructive <Spinner size=\"xs\" />\n    </Action>\n  </div>\n  <div className=\"row\">\n    <Button m=\"2\" disabled>\n      Disabled\n    </Button>\n    <Button m=\"2\" className=\"secondary disabled\">\n      Disabled\n    </Button>\n    <Box ml=\"170px\"></Box>\n    <Action m=\"2\" disabled>\n      Disabled\n    </Action>\n  </div>\n  <div style={{ marginBottom: \'8px\' }}>\n    <ButtonGroup actions={[{ label: \'Burrito\' }]} />\n  </div>\n  <div style={{ marginBottom: \'8px\' }}>\n    <ButtonGroup\n      actions={[\n        {\n          label: \'Burrito\',\n        },\n        {\n          label: \'Taco\',\n        },\n      ]}\n    />\n  </div>\n  <div style={{ marginBottom: \'8px\' }}>\n    <ButtonGroup\n      actions={[\n        {\n          label: \'Burrito\',\n        },\n        {\n          label: \'Taco\',\n        },\n        {\n          selected: true,\n          label: \'Pizza\',\n        },\n        {\n          disabled: true,\n          label: \'Hot Dog\',\n        },\n      ]}\n    />\n  </div>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Action,
      Box,
      Button,
      ButtonGroup,
      Spinner,
      Props,
      Playground,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
      <div className="row">
        <Button m='2' mdxType="Button">Default</Button>
        <Button m='2' className="secondary" mdxType="Button">Secondary</Button>
        <Button m='2' className="destructive" mdxType="Button">Destructive</Button>
        <Action m='2' mdxType="Action">Default</Action>
        <Action m='2' className="destructive" mdxType="Action">Destructive</Action>
      </div>
      <div className="row">
        <Button m='2' mdxType="Button">Default <Spinner size="xs" mdxType="Spinner" /></Button>
        <Button m='2' className="secondary" mdxType="Button">Secondary <Spinner size="xs" mdxType="Spinner" /></Button>
        <Button m='2' className="destructive" mdxType="Button">Destructive <Spinner size="xs" mdxType="Spinner" /></Button>
        <Action m='2' mdxType="Action">Default <Spinner size="xs" mdxType="Spinner" /></Action>
        <Action m='2' className="destructive" mdxType="Action">Destructive <Spinner size="xs" mdxType="Spinner" /></Action>
      </div>
      <div className='row'>
        <Button m='2' disabled mdxType="Button">Disabled</Button>
        <Button m='2' className="secondary disabled" mdxType="Button">Disabled</Button>
        <Box ml='170px' mdxType="Box"></Box>
        <Action m='2' disabled mdxType="Action">Disabled</Action>
      </div>
      <div style={{
          marginBottom: '8px'
        }}>
       <ButtonGroup actions={[{
            label: 'Burrito'
          }]} mdxType="ButtonGroup" />
     </div>
     <div style={{
          marginBottom: '8px'
        }}>
       <ButtonGroup actions={[{
            label: 'Burrito'
          }, {
            label: 'Taco'
          }]} mdxType="ButtonGroup" />
     </div>
     <div style={{
          marginBottom: '8px'
        }}>
       <ButtonGroup actions={[{
            label: 'Burrito'
          }, {
            label: 'Taco'
          }, {
            selected: true,
            label: 'Pizza'
          }, {
            disabled: true,
            label: 'Hot Dog'
          }]} mdxType="ButtonGroup" />
     </div>
  </ThemeProvider>
    </Playground>
    <h2 {...{
      "id": "button-1"
    }}>{`Button`}</h2>
    <p><inlineCode parentName="p">{`import { Button } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`A simple `}<inlineCode parentName="p">{`<Button>`}</inlineCode>{` prestyled in foodsby fashion. Pass it any `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` event. It's a button.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` property when you want to fully and completely disable a button from click and focus events. Use the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` class when you still want to allow click and focus and plan on hanlding it via JS (for example, clicking to scroll to a required field). `}</p>
    <h3 {...{
      "id": "properties"
    }}>{`Properties`}</h3>
    <Props of={Button} mdxType="Props" />
    <h2 {...{
      "id": "buttongroup"
    }}>{`ButtonGroup`}</h2>
    <h3 {...{
      "id": "properties-1"
    }}>{`Properties`}</h3>
    <Props of={ButtonGroup} mdxType="Props" /> 
    <p><inlineCode parentName="p">{`import { ButtonGroup } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`A component to show a list a actions styled as connected buttons.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      